<script setup>
import {onMounted, ref} from 'vue';
import Modal from "@/Components/Modal.vue";
import GenericButton from "@/Components/GenericButton.vue";
import FormInput from "@/Components/Form/FormInput.vue";
import { usePage } from '@inertiajs/vue3';

const emit = defineEmits(['update:toggleModal']);

const props = defineProps({
    inviteUserToEventBool: Boolean,
    eventId:Number,
    postId:Number
});

const form = ref({
    email: '',
    pageUrl: import.meta.env.VITE_APP_URL + route('agendas.events.attend', props.postId),
    event_id:props.eventId
});

const sendInvite = () => {
    axios({
        method: 'post',
        url: route('group.events.send.invite'),
        data: form.value
    }).then(response => {
        emit('update:toggleModal');
        emit('showToast', {message: response.data.message, type:'success'})
        showLink(response)
    }).catch(error => {
        emit('showToast', {message: error.response.data.message, type:'error'})
        // showLink(error.response);
    });
};
//this for testing link if don't work mail server
const showLink = (response) => {
    if (response.data?.link) {
        console.log('sent link: ', response.data.link);
    }
};


</script>
<template>
    <modal class=""
        @close="$emit('update:toggleModal')" :show="inviteUserToEventBool">
        <div class="p-4 overflow-hidden">
            <div class="flex justify-between mb-4">
                <h6 class="font-semibold text-center text-primary">
                    {{ $t('Invite team member') }}
                </h6>
            </div>
            <form class="flex flex-col gap-2"
                @submit.prevent="sendInvite">
                <div class="flex flex-col grid-cols-3 gap-2 sm:flex-row">
                    <div class="flex flex-col w-full mr-3">
                        <FormInput
                            type="email"
                            :label="$t('Email')"
                            v-model="form.email"
                            autofocus
                            required
                             />
                    </div>
                </div>
                <div class="flex justify-end w-full mt-4">
                    <div class="flex flex-row gap-2">
                    <GenericButton
                        @click.prevent="$emit('update:toggleModal')"
                        :size="'medium'"
                        :theme="'primary-outlined'"
                        type="button"
                        :text="$t('Cancel')"
                    />
                    <GenericButton
                        type="submit"
                        :size="'medium'"
                        :text="$t('Send Invite')"
                    />
                    </div>
                </div>
            </form>
        </div>
    </modal>
</template>
